import polyglotI18nProvider from "ra-i18n-polyglot";
import { resolveBrowserLocale } from "react-admin";
import englishMessages from "./en";
import spanishMessages from "./es";

const browserLocale = resolveBrowserLocale();

export default polyglotI18nProvider((locale) => {
  if (locale === "es") {
    return spanishMessages;
  }

  // Always fallback on english
  return spanishMessages;
}, "es");
import React from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  required,
  useTranslate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  warning: {
    height: 56,
    lineHeight: "56px",
    color: "grey",
  },

  customer: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
  },

  input: {
    width: "100%",
  },
}));

const CustomerInput = ({ source, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        const selectedProducts = formData.items_json || [];
        const disabled =
          Array.isArray(selectedProducts) && selectedProducts.length > 0;
        return (
          <div className={classes.customer}>
            <ReferenceInput
              className={classes.input}
              source={source}
              label={translate("resources.customers.search")}
              reference="customers"
              validate={required()}
            >
              <AutocompleteInput
                optionText="name"
                disabled={disabled}
                helperText={
                  disabled ? "resources.customers.selection_warning" : null
                }
              />
            </ReferenceInput>
          </div>
        );
      }}
    </FormDataConsumer>
  );
};

CustomerInput.defaultProps = {
  source: "customer_id",
};

export default CustomerInput;

import React, { useState, useCallback } from "react";
import { TextInput, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import useFormStyles from "../styles";
import CustomerInput from "./CustomerInput";
import CustomerCreateButton from "./CustomerCreateButton";

const useStyles = makeStyles(
  (theme) => ({
    content: {
      display: "flex",
      flexDirection: "row",
      marginRight: 40,
    },
    reference: {
      marginLeft: 'auto'
    }
  }),
  { name: "RoCustomerCard" }
);

const CustomerCard = (props) => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const translate = useTranslate();
  const [version, setVersion] = useState(0);
  const handleChange = useCallback(() => setVersion(version + 1), [version]);

  return (
    <Card className={formClasses.card}>
      <CardHeader title={translate("resources.customers.name", 0)} />
      <CardContent className={classes.content}>
        <CustomerInput />
        <CustomerCreateButton onChange={handleChange} />
        <div className={classes.reference}>
          <TextInput
            label={translate(`resources.${props.resource}.fields.customer_order_number`)}
            source="customer_order_number"
            {...props}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default CustomerCard;

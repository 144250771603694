import { makeStyles } from "@material-ui/core/styles";

export const tabletMediaquery = (theme) => theme.breakpoints.down("md");

const styles = makeStyles((theme) => ({
  tabletProductCell: {
    border: 0,
  },
}));

export default styles;

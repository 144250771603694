import jwt from "jwt-decode"; // import dependency

export const getCurrentUser = () => localStorage.getItem("user");

const authProvider = {
  login: (loginResponse) => {
    const { token} = loginResponse;

    localStorage.setItem("token", token);
    const user = jwt(token); // decode your token here
    localStorage.setItem("userId", user.id);
    localStorage.setItem("user", JSON.stringify(user));
    return Promise.resolve(true);
  },
  logout: () => {
    console.log("logout")
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth: () => {
    console.log("checkAuth")
    console.log(localStorage.getItem("token"))
    return localStorage.getItem("token")
      ? Promise.resolve({})
      : Promise.reject({});
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: (params) => Promise.resolve(),
};

export default authProvider;

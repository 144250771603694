import React from "react";
import {
  DateField,
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
} from "react-admin";

import ProductFilters from "./productFilters";
import CustomPagination from "../../components/Pagination";

const sort = { field: "name", order: "ASC" };

export default (props) => (
  <List 
    {...props}
    sort={sort}
    pagination={<CustomPagination />}
    perPage={50}
    filters={<ProductFilters />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="code" />
      <TextField source="cabys" />
      <TextField source="name" />
      <TextField source="product_brand" />
      <TextField source="unit" />
      <TextField source="presentation" />
      <TextField source="cost" />
      <TextField source="inventory" />
      <TextField source="active" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { statusColors } from "../statusList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    margin: 5,
    padding: 5,
    fontSize: 12,
    textTransform: "capitalize",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    border: "1px solid",
    borderColor: "transparent",
    height: 14,
    width: 120,
  },
  ...statusColors,
}));

export const OrderStatus = ({ id, translate }) => {
  const classes = useStyles();
  return (
    <span className={classnames(classes.root, classes[id] || classes.error)}>
      {translate(id ? `status.${id}` : "status.draft")}
    </span>
  );
};

OrderStatus.propTypes = {
  id: PropTypes.string,
  translate: PropTypes.func,
};

OrderStatus.defaultProps = {
  translate: (name) => name,
};

export default OrderStatus;

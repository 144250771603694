import React from "react";
import { SelectArrayInput, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { OrderStatus } from "./OrderStatus";
import statusList from "../statusList";

const useStyles = makeStyles({
  root: {
    minWidth: 210,
    maxWidth: 560,
    "& .MuiSelect-select": {
      height: "inherit",
    },
    "& .MuiChip-root": {
      backgroundColor: "transparent",
    },
  },
});

const optionRenderer = (choice, translate) => (
  <OrderStatus id={choice.name} translate={translate} />
);
const OrderStatusInput = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <SelectArrayInput
      {...props}
      className={classes.root}
      translateChoice={false}
      choices={statusList}
      optionText={(choice) => optionRenderer(choice, translate)}
    />
  );
};

OrderStatusInput.defaultProps = {
  source: "status",
};

export default OrderStatusInput;

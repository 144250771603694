import React from "react";
import PropTypes from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { updateValueInRows } from "./rowFunctions";

const EditableField = ({
  rows,
  updateRows,
  field,
  index,
  minValue,
  maxValue,
  adornment,
}) => {
  return (
    <TextField
      value={rows[index][field]}
      type="number"
      name={`product_${field}_${index}`}
      InputProps={{
        inputProps: { min: minValue, max: maxValue },
        endAdornment: adornment ? (
          <InputAdornment position="end">{adornment}</InputAdornment>
        ) : undefined,
      }}
      onFocus={(e) => {
        e.target.select();
      }}
      onChange={(e) => {
        const value =
          e.currentTarget.value != null
            ? parseFloat(e.currentTarget.value)
            : "";
        updateRows(
          updateValueInRows(rows, index, field, value, minValue, maxValue)
        );
      }}
    />
  );
};

EditableField.propTypes = {
  rows: PropTypes.array.isRequired,
  updateRows: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  adornment: PropTypes.string,
};

EditableField.defaultProps = {
  minValue: 0,
};

export default EditableField;

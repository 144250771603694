import React from "react";
import { Route } from "react-router-dom";
import {
  List,
  TextField,
  FunctionField,
  NumberField,
  ReferenceField,
  DateField,
} from "react-admin";
import { WAITING_APPROVAL, ON_HOLD } from "../statusList";
import ApprovalEdit, { ApprovalEmpty } from "./ApprovalEdit";
import Cardgrid from "./Cardgrid";
import { makeStyles } from "@material-ui/core/styles";

const sort = { field: "created_at", order: "ASC" };

const statusFilter = { status: [WAITING_APPROVAL, ON_HOLD] };

const useListStyles = makeStyles(
  {
    root: {
      "& > div > div:first-child": {
        backgroundColor: "transparent",
        boxShadow: "none",
        border: 0,
      },
    },
  },
  { name: "RaListCard" }
);

export default (props) => {
  const classes = useListStyles();
  return (
    <Route path={`/${props.resource}/:id`}>
      {({ match }) => {
        const selectedId =
          match && match.params && parseInt(match.params.id, 10);

        return (
          <List
            {...props}
            sort={sort}
            pagination={null}
            filterDefaultValues={statusFilter}
            perPage={200}
            className={classes.root}
            aside={
              selectedId ? <ApprovalEdit id={selectedId} /> : <ApprovalEmpty />
            }
            bulkActionButtons={false}
          >
            <Cardgrid rowClick="edit" selectedId={selectedId}>
              <DateField source="created_at" sortable={false} />
              <FunctionField
                source="total"
                sortable={false}
                render={(record) => (
                  <NumberField
                    record={record}
                    source="total"
                    sortable={false}
                    options={{
                      style: "currency",
                      currency: record["currency"],
                    }}
                  />
                )}
              />
              <FunctionField
                source="customer_order_number"
                sortable={false}
                render={(record) => `# ${record["customer_order_number"]}`}
              />
              <ReferenceField
                source="customer_id"
                reference="customers"
                sortable={false}
                link={false}
              >
                <TextField source="name" />
              </ReferenceField>
            </Cardgrid>
          </List>
        );
      }}
    </Route>
  );
};

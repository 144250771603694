import React, { useState, useRef } from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, useFormState } from "react-final-form";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import numeral from "numeral";
import useFormStyles from "../styles";
import ProductSelector from "./ProductSelector";
import ProductTable from "./ProductTable";
import { getRowsTotal, recomputeRowTotal } from "./rowFunctions";

const useStyles = makeStyles((theme) => ({
  selectionBox: {
    backgroundColor: "dimgrey",
    margin: `${theme.spacing(2)}px 0`,
  },
  selectionGrid: {
    backgroundColor: "dimgrey",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
  },
  totalGrid: {
    backgroundColor: "dimgrey",
    justifyContent: "flex-end",
  },
  total: {
    alignSelf: "center",
    padding: theme.spacing(1),
    marginRight: 74,
    fontWeight: "bolder",
  },
  selectContent: {
    paddingLeft: 0,
    boxShadow: "inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC",
  },
}));

const ProductsCard = (props) => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const listRef = useRef();
  const translate = useTranslate();
  const form = useForm();
  const formState = useFormState();

  const [rows, setRows] = useState(formState.values.items_json || []);

  const handleEditRows = (newRows) => {
    setRows(newRows);
    form.change("items_json", newRows);
    form.change("total", getRowsTotal(newRows));
    form.change("currency", "CRC");
  };

  const handleAdd = (item) => {
    if (rows.filter((row) => row.product_id === item.id).length > 0) {
      const removeRows = rows.filter((row) => row.product_id !== item.id);
      handleEditRows(removeRows);
      return;
    }
    const newRows = [
      ...rows,
      {
        product_id: item.id,
        name: item.name,
        amount: "",
        maxAmount: item.inventory,
        price: item.price || 0,
        discount: item.discount_rate || 0,
        code: item.code,
        cabys: item.cabys,
        unit: item.unit,
        common_amounts: item.common_amounts || [],
        total: recomputeRowTotal(item),
      },
    ];
    handleEditRows(newRows);
  };

  const customer_id = formState.values.customer_id;
  if (customer_id == null) {
    return <></>;
  }

  return (
    <Card className={formClasses.card}>
      <CardHeader title={translate("resources.products.name", 2)} />
      <CardContent ref={listRef} className={classes.selectContent}>
        <Box m={3} className={classes.selectionBox}>
          <Grid container className={classes.selectionGrid}>
            <ProductSelector
              customer_id={customer_id}
              rows={rows}
              onSelect={handleAdd}
              listRef={listRef}
              showInactive={false}
            />
          </Grid>
        </Box>
      </CardContent>
      {rows.length ? (
        <CardContent>
          <ProductTable
            updateRows={handleEditRows}
            rows={rows}
            translate={translate}
          />
          <Grid container className={classes.totalGrid}>
            <span className={classes.total}>
              {numeral(getRowsTotal(rows)).format("0,0.00")} CRC
            </span>
          </Grid>
        </CardContent>
      ) : null}
    </Card>
  );
};

ProductsCard.defaultProps = {
  source: "items_json",
};

export default ProductsCard;

import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { statusColors } from "../statusList";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    margin: 0,
    padding: 5,
    fontSize: "1rem",
    fontWeight: "bolder",
    textTransform: "capitalize",
    textAlign: "center",
    borderRadius: theme.shape.borderRadius,
    fontFamily: theme.typography.fontFamily,
    border: "1px solid",
    borderColor: "transparent",
    height: 24,
    width: "calc(100% - 16px)",
    color: "white",
  },
  ...statusColors,
}));

export const ApprovalStatus = ({ id, translate }) => {
  const classes = useStyles();
  return (
    <span className={classnames(classes.root, classes[id] || classes.error)}>
      {translate(id ? `status.${id}` : "status.draft")}
    </span>
  );
};

ApprovalStatus.propTypes = {
  id: PropTypes.string,
  translate: PropTypes.func,
};

ApprovalStatus.defaultProps = {
  translate: (name) => name,
};

export default ApprovalStatus;

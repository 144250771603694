import React from "react";
import { Toolbar, SaveButton } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      backgroundColor: "transparent",
    },
    save: {
      marginLeft: theme.spacing(2),
    },
  }),
  { name: "RoToolbarCard" }
);

const ToolbarCard = (props) => {
  const classes = useStyles();
  return (
    <Toolbar className={classes.toolbar} {...props}>
      <SaveButton className={classes.save} {...props} />
    </Toolbar>
  );
};

export default ToolbarCard;

import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/blue";
import teal from "@material-ui/core/colors/teal";
import green from "@material-ui/core/colors/green";

export const DRAFT = "draft";
export const WAITING_APPROVAL = "waiting_approval";
export const ON_HOLD = "on_hold";
export const APPROVED = "approved";
export const WAITING_SHIPMENT = "waiting_shipment";
export const DONE = "done";

export const statusColors = {
  [DRAFT]: {
    backgroundColor: grey[400],
  },
  [WAITING_APPROVAL]: {
    backgroundColor: blue[300],
  },
  [ON_HOLD]: {
    backgroundColor: orange[400],
    borderColor: red[600],
  },
  [APPROVED]: {
    backgroundColor: teal[300],
    borderColor: red[600],
  },
  [WAITING_SHIPMENT]: {
    backgroundColor: blue[700],
    color: "white",
  },
  [DONE]: {
    backgroundColor: green[400],
  },
  error: {
    backgroundColor: red[400],
  },
};

export default [
  { id: DRAFT, name: DRAFT },
  { id: WAITING_APPROVAL, name: WAITING_APPROVAL },
  { id: ON_HOLD, name: ON_HOLD },
  { id: APPROVED, name: APPROVED },
  { id: WAITING_SHIPMENT, name: WAITING_SHIPMENT },
  { id: DONE, name: DONE },
];

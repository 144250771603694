import React, { cloneElement, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import {
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
  List,
  TextField,
  Datagrid,
  BooleanField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
  DateField,
  useUpdate,
  useNotify,
  useRefresh,
  useDataProvider,
} from "react-admin";
import IconEvent from "@material-ui/icons/Event";

const ApproveButton = ({ selectedIds, resource }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const approve = () => {
    dataProvider.archive(resource, { ids: selectedIds }).then((response) => {
      refresh("/" + resource);
      notify("Comment approved");
    });
  };

  return <Button label="Approve" onClick={approve} />;
};

const PostBulkActionButtons = (props) => (
  <Fragment>
    <ApproveButton label="Reset Views" {...props} />
    {/* default bulk delete action */}
  </Fragment>
);

export default (props) => (
  <List {...props} bulkActionButtons={<PostBulkActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <TextField source="status" />
      <TextField source="type" />
      <TextField source="applied_at" />
      <BooleanField source="archived" />
      <ReferenceField source="approved_by_user_id" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField source="created_by_user_id" reference="users">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="reference" />
      <ArrayField source="items_json">
        <SingleFieldList>
          <ChipField source="cost" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import fetch from "cross-fetch";
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDataProvider, useNotify, useRedirect } from "react-admin";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Button from "@material-ui/core/Button";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Asynchronous(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const dataProvider = useDataProvider();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  React.useEffect(() => {
    let active = true;

    (async () => {
      if (searchTerm.length === 0) return undefined;
      setLoading(true);
      dataProvider
        .getList("products", {
          pagination: { page: 1, perPage: 10 },
          sort: { field: "name", order: "ASC" },
          filter: { name: `%${searchTerm}%` },
        })
        .then((result) => {
          setLoading(false);
          setOptions(result.data);
        });
    })();

    return () => {
      active = false;
    };
  }, [searchTerm, dataProvider]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    setOpen(false);
    setOptions([]);
    setSelected([]);
  }, [props.rows]);

  const onInputChange = (ev, val, reason) => {
    setSearchTerm(val);
  };

  return (
    <Autocomplete
      onInputChange={onInputChange}
      id="asynchronous-demo"
      multiple
      value={selected}
      onChange={(e, o) => {
        props.onSelect(o);
        setSelected(o);
      }}
      disableCloseOnSelect
      style={{ width: 500 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderOption={(option, { selected }) => {
        return (
          <React.Fragment>
            <Button>ok</Button>
            {option.name}
          </React.Fragment>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Products"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

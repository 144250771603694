import React from "react";
import { useTranslate } from "react-admin";
import PropTypes from "prop-types";

import OrderStatus from "./OrderStatus";

const OrderStatusField = ({ record, source }) => {
  const translate = useTranslate();
  return <OrderStatus id={record[source]} translate={translate} />;
};

OrderStatusField.propTypes = {
  record: PropTypes.shape({ status: PropTypes.string }),
  source: PropTypes.string,
};

OrderStatusField.defaultProps = {
  source: "status",
  addLabel: true,
};

export default OrderStatusField;

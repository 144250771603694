import React, { cloneElement, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import {
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
  List,
  TextField,
  Datagrid,
  BooleanField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
  DateField,
  useUpdate,
  useNotify,
  useRefresh,
  useDataProvider,
} from "react-admin";
import IconEvent from "@material-ui/icons/Event";
import CustomPagination from "../../components/Pagination";
import PriceListCustomerFilters from "./priceListCustomerFilters";


const ApproveButton = ({ selectedIds, resource }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const approve = () => {
    dataProvider.archive(resource, { ids: selectedIds }).then((response) => {
      refresh("/" + resource);
      notify("Comment approved");
    });
  };

  return <Button label="Approve" onClick={approve} />;
};

const PostBulkActionButtons = (props) => (
  <Fragment>
    <ApproveButton label="Reset Views" {...props} />
    {/* default bulk delete action */}
  </Fragment>
);

export default (props) => (
  <List {...props} 
  pagination={<CustomPagination />}
  perPage={50}
  filters={<PriceListCustomerFilters />}
  bulkActionButtons={<PostBulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <ReferenceField
        source="product_price_list_id"
        reference="product_price_lists"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="customer_id" reference="customers">
        <TextField source="name" />
      </ReferenceField>

      <DateField source="created_at" />
    </Datagrid>
  </List>
);

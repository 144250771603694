import React from "react";
import PropTypes from "prop-types";
import Done from "@material-ui/icons/Done";
import { useMutation, useNotify, useRedirect, Button } from "react-admin";

const ApproveButton = ({ record, resource, nextId }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [approve, { loading }] = useMutation(
    {
      type: "update",
      resource,
      payload: { data: { id: record.id }, action: "approve" },
    },
    {
      undoable: false,
      onSuccess: () => {
        notify(
          `resources.${resource}.notification.approve_success`,
          "info",
          {},
          false
        );
        redirectTo(`/${resource}/${nextId}`);
      },
      onFailure: () => {
        notify(`resources.${resource}.notification.approve_error`, "warning");
      },
    }
  );

  return record ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={approve}
      disabled={loading}
      label={`resources.${resource}.action.approve`}
    >
      <Done color="primary" style={{ color: "green" }} />
    </Button>
  ) : (
    <span />
  );
};

ApproveButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  nextId: PropTypes.number,
};

export default ApproveButton;

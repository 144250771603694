import { AutocompleteInput } from "react-admin";

import React from "react";
import {
  Show,
  ShowButton,
  SimpleShowLayout,
  RichTextField,
  DateField,
  List,
  Edit,
  Create,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
  EditButton,
  ReferenceInput,
  BooleanField,
  NumberInput,
  RadioButtonGroupInput,
  SelectInput,
  SimpleForm,
  BooleanInput,
  TextInput,
  email,
  Filter,
} from "react-admin";

const validateEmail = email();

export default (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="code" />
        <TextInput source="name" />

        <ReferenceInput
          label="Marca"
          source="product_brand_id"
          reference="product_brands"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <RadioButtonGroupInput
          source="unit"
          choices={[
            { id: "kg", name: "kg" },
            { id: "unid", name: "unidad" },
            { id: "m", name: "metro" },
          ]}
        />

        <TextInput source="presentation" />
        <NumberInput source="price" />
        <NumberInput source="cost" />
        <NumberInput source="inventory" />
        <NumberInput source="cabis" />
      </SimpleForm>
    </Edit>
  );
};

import icon from "@material-ui/icons/VerifiedUser";

import ApprovalList from "./ApprovalList";

export const ApprovalIcon = icon;

export default {
  icon,
  list: ApprovalList,
};

import React from "react";
import { FC, useState } from 'react';
import { useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { useTranslate, MenuItemLink } from "react-admin";
import { Switch, Divider, FormControlLabel } from "@material-ui/core";

import orders from "../sales/orders";
import inventory from "../inventory";
import approvals from "../sales/approvals";
import customers from "../customer";
import invoices from "../sales/invoices";
import transports from "../sales/transport";
import customerReps from "../customer/customerReps";
import customerRegions from "../customer/customerRegions";
import products from "../inventory/product";
import productBrands from "../inventory/brand";
import purchases from "../inventory/purchase";
import treasury from "../treasury";
import receipts from "../treasury/receipts";
import priceList from "../sales/priceList";
import priceListItems from "../sales/priceListItem";
import priceListCustomer from "../sales/priceListCustomer";


import users from "../settings/users";
import { toggleSidebar } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";
import SubMenu from './SubMenu';

import {
  createMuiTheme,
  withStyles,
  createStyles,
} from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    height: "calc( 100vh - 1.5em )",
    backgroundColor: "#ebebeb",
    display: "flex",
    flexDirection: "column",
    marginTop: "0",
          backgroundColor: "dimgrey",

  },
});

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers' | 'menuCatalog' | 'menuTreasury';

const Menu = ({ onMenuClick, dense, logout, showComm }) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const classes = useStyles();
  const [state, setState] = useState({
    menuCatalog: false,
    menuSales: false,
    menuCustomers: false,
    menuTreasury: false,
    menuSettings: false
});
  const handleToggle = (menu: MenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
};

  return (
    <div className={classes.root}>
      <div style={{ flexGrow: "1" }}>
        <SubMenu
          handleToggle={() => handleToggle('menuSales')}
          isOpen={state.menuSales}
          sidebarIsOpen={open}
          name={translate(`menu.sales`)}
          icon={<orders.icon />}
          dense={dense}
        >        
            <MenuItemLink
              to={`/orders`}
              primaryText={translate(`resources.orders.name`, {
                smart_count: 2,
              })}
              leftIcon={<orders.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/invoices`}
              primaryText={translate(`resources.invoices.name`, {
                smart_count: 2,
              })}
              leftIcon={<invoices.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
            to={`/product_price_lists`}
            primaryText={translate(`resources.product_price_lists.name`, {
              smart_count: 2,
            })}
            leftIcon={<priceList.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/product_price_list_items`}
            primaryText={translate(`resources.product_price_list_items.name`, {
              smart_count: 2,
            })}
            leftIcon={<priceListItems.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
            <MenuItemLink
            to={`/product_price_list_customers`}
            primaryText={translate(`resources.product_price_list_customers.name`, {
              smart_count: 2,
            })}
            leftIcon={<priceListCustomer.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
            {/* 
            <MenuItemLink
              to={`/quotes`}
              primaryText={translate(`resources.quotes.name`, {
                smart_count: 2,
              })}
              leftIcon={<orders.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />

            <MenuItemLink
              to={`/approvals`}
              primaryText={translate(`resources.approvals.name`, {
                smart_count: 2,
              })}
              leftIcon={<approvals.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            */}
        </SubMenu>
        <SubMenu
          handleToggle={() => handleToggle('menuCustomers')}
          isOpen={state.menuCustomers}
          sidebarIsOpen={open}
          name={translate(`menu.customers`)}
          icon={<customers.icon />}
          dense={dense}
        >    
          <MenuItemLink
              to={`/customers`}
              primaryText={translate(`resources.customers.name`, {
                smart_count: 2,
              })}
              leftIcon={<customers.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
          />
          <MenuItemLink
              to={`/customer_reps`}
              primaryText={translate(`resources.customer_reps.name`, {
                smart_count: 2,
              })}
              leftIcon={<customerReps.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
          />
          <MenuItemLink
              to={`/customer_regions`}
              primaryText={translate(`resources.customer_regions.name`, {
                smart_count: 2,
              })}
              leftIcon={<customerRegions.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
          />
        </SubMenu>
        <SubMenu
          handleToggle={() => handleToggle('menuCatalog')}
          isOpen={state.menuCatalog}
          sidebarIsOpen={open}
          name={translate(`menu.inventory`)}
          icon={<inventory.icon />}
          dense={dense}
        >    
          <MenuItemLink
            to={`/products`}
            primaryText={translate(`resources.products.name`, {
              smart_count: 2,
            })}
            leftIcon={<products.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/product_brands`}
            primaryText={translate(`resources.product_brands.name`, {
              smart_count: 2,
            })}
            leftIcon={<productBrands.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/purchases`}
            primaryText={translate(`resources.purchases.name`, {
              smart_count: 2,
            })}
            leftIcon={<purchases.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />  
          <MenuItemLink
            to={`/transports`}
            primaryText={translate(`resources.transports.name`, {
              smart_count: 2,
            })}
            leftIcon={<transports.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          {/*
          <MenuItemLink
            to={`/shipping_routes`}
            primaryText={translate(`resources.shipping_routes.name`, {
              smart_count: 2,
            })}
            leftIcon={<productBrands.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/packing_lists`}
            primaryText={translate(`resources.packing_lists.name`, {
              smart_count: 2,
            })}
            leftIcon={<productBrands.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />  
          <MenuItemLink
            to={`/trucks`}
            primaryText={translate(`resources.trucks.name`, {
              smart_count: 2,
            })}
            leftIcon={<productBrands.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />  
          */}
        </SubMenu>
        <SubMenu
          handleToggle={() => handleToggle('menuTreasury')}
          isOpen={state.menuTreasury}
          sidebarIsOpen={open}
          name={translate(`menu.treasury`)}
          icon={<treasury.icon />}
          dense={dense}
        >        
            <MenuItemLink
              to={`/receipts`}
              primaryText={translate(`resources.receipts.name`, {
                smart_count: 2,
              })}
              leftIcon={<receipts.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
        </SubMenu>
        <MenuItemLink
          to={`/users`}
          primaryText={translate(`resources.users.name`, {
            smart_count: 2,
          })}
          leftIcon={<users.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </div>
      {isXSmall && logout}
    </div>
  );
};

export default Menu;

import React from "react";
import {
  DateField,
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
} from "react-admin";

import CustomerFilters from "./customerFilters";
import CustomPagination from "../components/Pagination";

const sort = { field: "name", order: "ASC" };

export default (props) => (
  <List 
    {...props}
    sort={sort}
    pagination={<CustomPagination />}
    perPage={50}
    filters={<CustomerFilters />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <TextField source="name" />

      <DateField source="created_at" />
      <TextField source="credit_limit" />
      <NumberField source="credit_term" />

      <TextField source="phone" />
      <TextField source="province" />

      <BooleanField source="active" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

import React, { Fragment, cloneElement } from "react";
import PropTypes from "prop-types";
import { useTranslate } from "react-admin";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import classnames from "classnames";
import ApprovalStatus from "./ApprovalStatus";
import { WAITING_APPROVAL, ON_HOLD } from "../statusList";
import CardgridRow from "./CardgridRow";

const CardgridBody = ({
  basePath,
  children,
  classes,
  className,
  data,
  expand,
  hasBulkActions,
  hover,
  ids,
  onToggleItem,
  resource,
  row,
  rowClick,
  rowStyle,
  selectedId,
  styles,
  version,
  isRowSelectable,
  ...rest
}) => {
  const translate = useTranslate();
  const groupedIds = ids.reduce((groups, id) => {
    const status = data[id].status;
    if (groups[data[id].status] === undefined) {
      return { ...groups, [status]: [id] };
    }
    return { ...groups, [status]: [...groups[status], id] };
  }, {});
  return (
    <Fragment>
      {Object.keys(groupedIds)
        .filter((item) => item === WAITING_APPROVAL || item === ON_HOLD)
        .sort((groupA, groupB) => (groupA === ON_HOLD ? 1 : -1))
        .map((key, keyIndex) => (
          <Fragment key={keyIndex}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.cell}
                  colSpan={children.length}
                  align="center"
                  size="medium"
                >
                  <ApprovalStatus id={key} translate={translate} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              key={`b${keyIndex}`}
              className={classnames("datagrid-body", className)}
              {...rest}
            >
              {groupedIds[key].map((id, rowIndex) =>
                cloneElement(
                  row,
                  {
                    basePath,
                    classes,
                    className: classnames(classes.row, {
                      [classes.rowEven]: rowIndex % 2 === 0,
                      [classes.rowOdd]: rowIndex % 2 !== 0,
                      [classes.clickableRow]: rowClick,
                    }),
                    expand,
                    hasBulkActions,
                    hover,
                    id,
                    key: id,
                    onToggleItem,
                    record: data[id],
                    resource,
                    rowClick,
                    selectable: !isRowSelectable || isRowSelectable(data[id]),
                    selectedId,
                    style: rowStyle ? rowStyle(data[id], rowIndex) : null,
                  },
                  children
                )
              )}
            </TableBody>
          </Fragment>
        ))}
    </Fragment>
  );
};

CardgridBody.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.object.isRequired,
  expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  row: PropTypes.element,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  styles: PropTypes.object,
  isRowSelectable: PropTypes.func,
  version: PropTypes.number,
};

CardgridBody.defaultProps = {
  data: {},
  hasBulkActions: false,
  ids: [],
  row: <CardgridRow />,
};
// trick material-ui Table into thinking this is one of the child type it supports
CardgridBody.muiName = "TableBody";

export default CardgridBody;

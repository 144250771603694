import merge from "lodash/merge";
import spanishMessages from "ra-language-spanish";

const fullSpanishMessages = merge({}, spanishMessages, {
  ra: {
    action: {
      export: "Exportación",
      search: "Buscar"
    },
    navigation: {
      page_rows_per_page: "Filas por página",
    },
    page: {
      empty: "Nada %{name} todavía",
      invite: "¿Quieres añadir uno?",
    },
    auth: {
      user_menu: "Perfil",
    },
    notification: {
      logged_out: "Log out"
    },
    button: {
      save: "Guardar"
    }
  },
});

export default {
  ...fullSpanishMessages,

  login: {
    getcode: "Obtener Codigo",
    login: "Entrar",
    email: "Correo",
    code: "Codigo",
    error: "A ocurrido un error al intentar entrar",
    invalid: "Correo o contraseña incorrecta email",
  },

  menu: {
    sales: "Ventas",
    customers: "Clientes",
    products: "Productos",
    inventory: "Inventario",
    treasury: "Tesoreria"
  },

  status: {
    draft: "Por Aplicar",
    waiting_approval: "Por Aprobar",
    on_hold: "En espera",
    approved: "Aprobado",
    waiting_shipment: "Por facturar",
    archived: "Archivado",
  },

  region_name: "Región",
  actions: "Acciones",
  current_user: "Yo",
  resources: {
    orders: {
      name: "Orden |||| Ordenes",
      fields: {
        customer_id: "Cliente",
        customer_order_number: "Numero de orden",
      },
      notification: {
          apply_success: "Orden aplicada exitosamente" ,
          shipment_error: "Error al facturar",
          shipment_success: "Factura Creada Exitosamente",
          shipment_no_pdf: "No se encontro PDF para esta factura"
        }
    },
    invoices: {
      name: "Factura |||| Facturas",
      fields: {
        customer_id: "Cliente",
      },
      action: {
        approve: "Approve",
        revert: "Revertir",
      },
      notification: {
        revert_success: "Las facturas se revirtieron exitosamente" ,
        revert_error: "Error al revertir facturas",
      }
    },
    approvals: {
      name: "Orden Aprobación |||| Ordenes Aprobación",
      fields: {
        customer_id: "Cliente",
      },
    },
    customers: {
      name: "Cliente |||| Clientes",
      fields: {},
      search: "Buscar Cliente",
      selection_warning: "No se puede editar el cliente"
    },
    customer_reps: {
      name: "Vendedor |||| Vendedores",
      fields: {},
    },
    customer_regions: {
      name: "Region |||| Regiones",
      fields: {},
    },
    products: {
      name: "Producto |||| Productos",
      available: "Disponibles",
      no_result: "Ningun resultado",
      fields: {
        amount: "Cantidad",
        name: "Nombre",
        price: "Precio",
        discount: "Descuento",
        total: "Total"
      },
      search: "Buscar producto"
    },
    packing_lists: {
      name: "Packing list |||| Packing lists",
      fields: {},
    },
    shipping_routes: {
      name: "Shipping route |||| Shipping routes",
      fields: {},
    },
    product_price_lists: {
      name: "Lista de Precios",
      fields: {},
    },
    product_price_list_items: {
      name: "Lista de Precios %",
      fields: {},
    },
    product_price_list_customers: {
      name: "Lista Precio Por Cliente",
      fields: {},
    },
    product_brands: {
      name: "Marca |||| Marcas",
      fields: {},
    },
    transports: {
      name: "Transporte |||| Transportes",
      fields: {
        name: "Nombre",
        product_price_list_id: "Lista de precios de los productos",
        product_id: "Producto",
        markup: "Markup",
        created_at: "Creado",
        updated_at: "Actualizado",
      },
    },
    trucks: {
      name: "Camión |||| Camiónes",
      fields: {},
    },
    users: {
      name: "Usuario |||| Usuarios",
      fields: {},
    },
    purchases: {
      name: "Compra |||| Compras",
      fields:{}
    },
    receipts: {
      name: "Recibo |||| Recibos",
      fields:{}
    }
  },
};

import React from "react";
import PropTypes from "prop-types";
import Hourglass from "@material-ui/icons/HourglassEmpty";
import { useMutation, useNotify, useRedirect, Button } from "react-admin";
import { ON_HOLD } from "../statusList";

const HoldButton = ({ record, resource, nextId }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [hold, { loading }] = useMutation(
    {
      type: "update",
      resource,
      payload: { data: { id: record.id }, action: "hold" },
      undoable: false,
    },
    {
      undoable: false,
      onSuccess: () => {
        notify(
          `resources.${resource}.notification.hold_success`,
          "info",
          {},
          false
        );
        redirectTo(`/${resource}/${nextId}`);
      },
      onFailure: () => {
        notify(`resources.${resource}.notification.hold_error`, "warning");
      },
    }
  );

  return record ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={hold}
      disabled={loading || record.status === ON_HOLD}
      label={`resources.${resource}.action.hold`}
    >
      <Hourglass color="primary" style={{ color: "red" }} />
    </Button>
  ) : (
    <span />
  );
};

HoldButton.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  nextId: PropTypes.number,
};

export default HoldButton;

import React from "react";
import { Admin, Resource, ListGuesser } from "react-admin";
import { useMediaQuery } from "@material-ui/core";

import CustomLayout from "./layout/CustomLayout";

import {
  CustomerCreate,
  CustomerList,
  CustomerEdit,
  CustomerRepsCreate,
  CustomerRepsList,
  CustomerRegionCreate,
  CustomerRegionList
} from "./customer";

import {
  InvoiceList,
} from "./sales";

import { BrandCreate, ProductCreate, ProductList, ProductEdit,  PurchaseEdit, PurchaseCreate, PurchaseList } from "./inventory";

import { ReceiptCreate, ReceiptList } from "./treasury";

import {
  PriceListCreate,
  PriceListList,
  PriceListItemList,
  PriceListItemCreate,
  PriceListCustomerList,
  PriceListCustomerCreate,
  TransportCreate,
  TransportList,
} from "./sales";

import orders from "./sales/orders";
import invoices from "./sales/invoices";
import approvals from "./sales/approvals";

import authProvider from "./auth/provider";
import LoginPage from "./settings/Login";
import dataProvider from "./data/provider";
import i18nProvider from "./i18n/i18nProvider";

const finalInitialState = {
  admin: {
    ui: {
      sidebarOpen: false,
      viewVersion: 0,
    },
  },
};

const App = () => {
  const isTablet = useMediaQuery("@media (max-width: 1024px)");
  return (
    <Admin
      layout={CustomLayout}
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      initialState={finalInitialState}
    >
      <Resource name="orders" {...orders} />
      <Resource name="approvals" {...approvals} />
      <Resource name="customers" create={CustomerCreate} list={CustomerList} edit={CustomerEdit}/>
      <Resource name="invoices" {...invoices} />
      <Resource name="users" list={ListGuesser} />
      <Resource name="customer_regions" create={CustomerRegionCreate} list={CustomerRegionList} edit={CustomerRegionCreate}/>

      <Resource
        name="customer_reps"
        list={CustomerRepsList}
        create={CustomerRepsCreate}
        edit={CustomerRepsCreate}
      />

      <Resource name="products" create={ProductCreate} list={ProductList} edit={ProductEdit}/>

      <Resource name="purchases" create={PurchaseCreate} list={PurchaseList} edit={PurchaseEdit}/>

      <Resource name="receipts" create={ReceiptCreate} list={ReceiptList} edit={ReceiptCreate}/>

      <Resource name="quotes" list={ListGuesser} />

      <Resource name="packing_lists" list={ListGuesser} />

      <Resource name="shipping_routes" list={ListGuesser} />

      <Resource
        name="product_price_lists"
        create={PriceListCreate}
        list={PriceListList}
      />
      <Resource
        name="product_price_list_items"
        create={PriceListItemCreate}
        list={PriceListItemList}
      />
      <Resource
        name="product_price_list_customers"
        create={PriceListCustomerCreate}
        list={PriceListCustomerList}
      />

      <Resource name="product_brands" create={BrandCreate} list={ListGuesser} />

      <Resource
        name="transports"
        list={TransportList}
        create={TransportCreate}
        edit={TransportCreate}
      />

      <Resource name="trucks" list={ListGuesser} />
    </Admin>
  );
};

export default App;

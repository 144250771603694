import { makeStyles } from "@material-ui/core/styles";

const useFormStyles = makeStyles(
  (theme) => ({
    root: {
      "& > div > div:first-child": {
        backgroundColor: "transparent",
        boxShadow: "none",
        border: 0,
        "& > form > div": {
          padding: 0,
        },
      },
    },
    card: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  }),
  { name: "RoCard" }
);

export default useFormStyles;

import React, { Fragment } from "react";
import MuiToolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import { WAITING_APPROVAL, ON_HOLD } from "../statusList";

import ApproveButton from "./ApproveButton";
import HoldButton from "./HoldButton";
import NavigateButton from "./NavigateButton";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "space-around",
  },
}));

const ReviewEditToolbar = ({ record, ids, data, resource }) => {
  const classes = useStyles();

  const waitingIds = ids.filter((id) => data[id].status === WAITING_APPROVAL);
  const holdIds = ids.filter((id) => data[id].status === ON_HOLD);

  const sortedIds = [...waitingIds, ...holdIds];

  const currentIndex = sortedIds.indexOf(record.id);
  const nextIndex =
    currentIndex >= sortedIds.length - 1 ? null : currentIndex + 1;
  const previousIndex = currentIndex <= 0 ? null : currentIndex - 1;
  return (
    <MuiToolbar className={classes.root}>
      <Fragment>
        <NavigateButton
          id={sortedIds[previousIndex]}
          resource={resource}
          left
        />
        <ApproveButton
          record={record}
          resource={resource}
          nextId={sortedIds[nextIndex]}
        />
        <HoldButton
          record={record}
          resource={resource}
          nextId={sortedIds[nextIndex]}
        />
        <NavigateButton id={sortedIds[nextIndex]} resource={resource} right />
      </Fragment>
    </MuiToolbar>
  );
};

export default ReviewEditToolbar;

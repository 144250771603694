import React from "react";
import {
  List,
  TextField,
  NumberField,
  Datagrid,
  ReferenceField,
  DateField,
} from "react-admin";
import CustomPagination from "../../components/Pagination";
import OrderStatusField from "./OrderStatusField";
import OrderActionsField from "./OrderActionsField";
import OrdersFilters, { defaultFilters } from "./OrdersFilters";

const sort = { field: "status", order: "ASC" };

export default (props) => (
  <List
    {...props}
    sort={sort}
    pagination={<CustomPagination />}
    perPage={50}
    filters={<OrdersFilters />}
    filterDefaultValues={defaultFilters()}
  >
    <Datagrid rowClick="edit">
      <DateField source="created_at" />
      <ReferenceField source="customer_id" reference="customers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="customer_order_number" />
      <NumberField source="total" />
      <ReferenceField source="created_by_user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="region_name"
        source="customer_id"
        reference="customers"
        link={false}
      >
        <ReferenceField
          source="customer_region_id"
          reference="customer_regions"
        >
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <OrderStatusField />
      <OrderActionsField />
    </Datagrid>
  </List>
);

import React from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 350,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "dimgrey"
  },
  content: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

const CustomTextComponent = ({ text, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={classes.container}>
      <span className={classes.content}>{text}</span>
    </div>
  );
};

export default CustomTextComponent;

export const recomputeRowTotal = (row) => {
  const total =
    ((row.price || 0) * (row.amount || 0) * (100 - (row.discount || 0))) / 100;
  return { ...row, total: Math.round(total * 100) / 100 };
};

export const getRowsTotal = (rows) => {
  const total = rows.reduce((total, value) => total + value.total, 0);
  return Math.round(total * 100) / 100;
};

export const updateValueInRows = (
  rows,
  index,
  field,
  value,
  minValue,
  maxValue
) => {
  const newRows = rows.map((row, i) => {
    let newValue = value ? parseFloat(value) : "";
    if (i === index) {
      if (minValue != null && value < minValue) {
        newValue = minValue;
      }
      if (maxValue != null && value > maxValue) {
        newValue = maxValue;
      }
      return recomputeRowTotal({
        ...row,
        [field]: newValue,
      });
    }
    return row;
  });
  return newRows;
};

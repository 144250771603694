import React from "react";
import { Edit, SimpleForm } from "react-admin";

import useFormStyles from "./styles";
import CustomerCard from "./createEditCards/CustomerCard";
import ProductsCard from "./productsSelector/ProductsCard";
import ToolbarCard from "./createEditCards/ToolbarCard";

export default (props) => {
  const classes = useFormStyles();
  return (
    <Edit className={classes.root} {...props}>
      <SimpleForm toolbar={<ToolbarCard />} redirect="list">
        <CustomerCard />
        <ProductsCard />
      </SimpleForm>
    </Edit>
  );
};

import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-admin";
import LeftIcon from "@material-ui/icons/ArrowBack";
import RightIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";

const NavigateButton = ({ id, resource, left, right }) => {
  const history = useHistory();

  const handleMove = () => {
    history.push(`/${resource}/${id}`);
  };

  const disabled = id == null;

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={handleMove}
      disabled={disabled}
    >
      {left ? (
        <LeftIcon color={disabled ? "disabled" : "primary"} />
      ) : right ? (
        <RightIcon color={disabled ? "disabled" : "primary"} />
      ) : null}
    </Button>
  );
};

NavigateButton.propTypes = {
  id: PropTypes.number,
  resource: PropTypes.string,
};

export default NavigateButton;

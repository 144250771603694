import React from "react";
import {
  useEditController,
  useTranslate,
  TabbedForm,
  FormTab,
  ReferenceField,
  DateField,
  TextField,
  NumberField,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import ApprovalEditToolbar from "./ApprovalEditToolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      margin: "1em",
      marginTop: 0,
      top: 60,
      position: "sticky",
      height: "fit-content",
    },
    [theme.breakpoints.down("sm")]: {
      width: 0,
      overflowX: "hidden",
      margin: 0,
    },
  },
  message: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(1),
  },
}));

export const ApprovalEmpty = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Card className={classes.root}>
      <Typography className={classes.message} variant="h6">
        {translate("resources.approvals.empty")}
      </Typography>
    </Card>
  );
};

const ApprovalEdit = (props) => {
  const classes = useStyles();
  const controllerProps = useEditController(props);
  if (!controllerProps.record) {
    return <ApprovalEmpty />;
  }

  return (
    <Card className={classes.root}>
      <TabbedForm
        className={classes.form}
        basePath={controllerProps.basePath}
        record={controllerProps.record}
        save={controllerProps.save}
        version={controllerProps.version}
        redirect="list"
        resource={props.resource}
        toolbar={<ApprovalEditToolbar ids={props.ids} data={props.data} />}
      >
        <FormTab label="resources.approvals.tabs.main">
          <DateField source="created_at" />
          <ReferenceField source="customer_id" reference="customers">
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="total" />
        </FormTab>
      </TabbedForm>
    </Card>
  );
};

export default ApprovalEdit;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import NumPad from "react-numpad";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CreateIcon from "@material-ui/icons/Create";
import { tabletMediaquery } from "./deviceStyles";
import { updateValueInRows } from "./rowFunctions";

const padTheme = {
  header: {
    primaryColor: "black",
    secondaryColor: "white",
    highlightColor: "#FFC107",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    buttonSize: "1.5em",
    order: 3,
  },
  subHeader: {
    primaryColor: "black",
    emptyVisibility: "hidden",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    order: 1,
  },
  body: {
    primaryColor: "#263238",
    secondaryColor: "#32a5f2",
    highlightColor: "#FFC107",
    backgroundColor: "white",
    order: 2,
  },
  panel: {
    backgroundColor: "white",
  },
  global: {
    fontFamily: "inherit",
    flexDirection: "column",
  },
};

const useStyles = makeStyles((theme) => ({
  customButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  editIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const FastAmountField = ({ rows, updateRows, index }) => {
  const classes = useStyles();
  const isTablet = useMediaQuery(tabletMediaquery);

  const [values, setValues] = useState({ fastValue: null, typedValue: "" });
  const row = rows[index];

  const handleFastAmountClick = (amount, maxValue) => {
    setValues({
      fastValue: amount > maxValue ? maxValue : amount,
      typedValue: "",
    });
    updateRows(updateValueInRows(rows, index, "amount", amount, 0, maxValue));
  };

  const handleTypingAmount = (amount, maxValue) => {
    setValues({
      fastValue: null,
      typedValue: amount > maxValue ? maxValue : amount,
    });
    updateRows(updateValueInRows(rows, index, "amount", amount, 0, maxValue));
  };

  return (
    <>
      {isTablet && (
        <ButtonGroup
          variant="outlined"
          color="primary"
          aria-label="fast amount selection"
        >
          {row.common_amounts.map((amount, index) => (
            <Button
              key={`common${index}`}
              disabled={amount > row.maxAmount}
              variant={amount === values.fastValue ? "contained" : "outlined"}
              onClick={() => handleFastAmountClick(amount, row.maxAmount)}
            >
              {amount}
            </Button>
          ))}
          <NumPad.Number
            value={values.typedValue}
            decimal={true}
            negative={false}
            position="center"
            update={() => {}}
            confirm={() => {}}
            confirmOutside={false}
            onChange={(value) => handleTypingAmount(value, row.maxAmount)}
            theme={padTheme}
          >
            <Button
              tabindex="-1"
              key="custom"
              color="primary"
              className={classes.customButton}
              variant={values.typedValue ? "contained" : "outlined"}
            >
              {values.typedValue}{" "}
              <CreateIcon className={values.typedValue && classes.editIcon} />
            </Button>
          </NumPad.Number>
        </ButtonGroup>
      )}
      {!isTablet && (
        <TextField
          value={values.typedValue}
          type="number"
          name={`product_amount_${index}`}
          onFocus={(e) => {
            e.target.select();
          }}
          InputProps={{ inputProps: { min: 0, max: row.maxAmount } }}
          onChange={(e) => {
            const value =
              e.currentTarget.value != null
                ? parseFloat(e.currentTarget.value)
                : "";
            handleTypingAmount(value, row.maxAmount);
          }}
        />
      )}
    </>
  );
};

FastAmountField.propTypes = {
  rows: PropTypes.array.isRequired,
  updateRows: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  maxValue: PropTypes.number,
};

export default FastAmountField;

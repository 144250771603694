import React, { cloneElement, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import Hourglass from "@material-ui/icons/HourglassEmpty";
import {
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
  List,
  TextField,
  Datagrid,
  BooleanField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
  DateField,
  useUpdate,
  useNotify,
  useRefresh,
  useDataProvider,
  NumberField,
  UrlField,
  useRedirect,
  useMutation
} from "react-admin";
import IconEvent from "@material-ui/icons/Event";

const RevertButton = ({ selectedIds, resource }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [revert, { loading }] = useMutation(
    {
      type: "update",
      resource: 'goverments',
      payload: { data: { ids: selectedIds }, action: "reSend" },
      undoable: false,
    },
    {
      onSuccess: () => {
        notify(
          `resources.${resource}.notification.revert_success`,
          "info",
          {},
          false
        );
        redirectTo(`/${resource}`);
      },
      onFailure: () => {
        notify(`resources.${resource}.notification.revert_error`, "warning");
      },
    }
  );

  return selectedIds ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={revert}
      label={`resources.${resource}.action.revert`}
    >
      <Hourglass color="primary" style={{ color: "red" }} />
    </Button>
  ) : (
    <span />
  );
};

const PostBulkActionButtons = (props) => (
  <Fragment>
    <RevertButton label="Reset Views" {...props} />
    {/* default bulk delete action */}
  </Fragment>
);

export default (props) => (
  <List {...props} bulkActionButtons={<PostBulkActionButtons />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="number" />
      <ReferenceField source="customer_id" reference="customers">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="balance" />
      <NumberField source="totalGross" />
      <TextField source="statusMessage" />
      <UrlField source="pdf" />
      <UrlField source="xml" />
      <UrlField source="Respose" />

    </Datagrid>
  </List>
);

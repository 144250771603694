import React, { cloneElement } from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useRowStyles = makeStyles((theme) => ({
  cell: {
    border: 0,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    padding: theme.spacing(1),
    cursor: "pointer",
    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
  },
  selectedRow: {
    backgroundColor: "rgba(33, 150, 243, 0.2)",
  },
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 1rem",
  },
  bottom: {
    padding: "1rem",
    "& > span": {
      fontSize: "1rem",
      fontWeight: "bolder",
    },
  },
  date: {
    width: 100,
    textAlign: "start",
  },
  currency: {
    textAlign: "end",
  },
  reference: {
    width: 150,
    textAlign: "end",
    fontStyle: "italic",
  },
}));

const CardgridRow = ({
  record,
  resource,
  id,
  children,
  selectedId,
  basePath,
}) => {
  const classes = useRowStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/${resource}/${id}`);
  };

  const getChildElement = (source) =>
    React.Children.toArray(children).find(
      (item) => item.props.source === source
    );

  return (
    <TableRow key={id}>
      <TableCell className={classes.cell}>
        <Card
          className={classnames(classes.card, {
            [classes.selectedRow]: selectedId === id,
          })}
          onClick={handleClick}
        >
          <div className={classes.top}>
            {cloneElement(getChildElement("created_at"), {
              record,
              basePath,
              resource,
              className: classes.date,
            })}
            {cloneElement(getChildElement("total"), {
              record,
              basePath,
              resource,
              className: classes.currency,
            })}
            {cloneElement(getChildElement("customer_order_number"), {
              record,
              basePath,
              resource,
              className: classes.reference,
            })}
          </div>
          <div className={classes.bottom}>
            {cloneElement(getChildElement("customer_id"), {
              record,
              basePath,
              resource,
            })}
          </div>
        </Card>
      </TableCell>
    </TableRow>
  );
};

CardgridRow.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.number,
  children: PropTypes.node,
  selectedId: PropTypes.number,
  basePath: PropTypes.string,
};

export default CardgridRow;

import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Button from "@material-ui/core/Button";
import numeral from "numeral";
import EditableField from "./EditableField";
import FastAmountField from "./FastAmountField";
import useDeviceStyles, { tabletMediaquery } from "./deviceStyles";

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: "dimgrey",
    minWidth: 650,
  },
  discountCell: {
    width: 60,
  },
  totalCell: {
    minWidth: 100,
  },
  deleteCell: {
    width: 60,
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const ProductTable = ({ rows, updateRows, translate }) => {
  const classes = useStyles();
  const deviceClasses = useDeviceStyles();
  const isTablet = useMediaQuery(tabletMediaquery);

  const removeItem = (index) => {
    let newRows = [];
    rows.map((row, i) => {
      if (i !== index) {
        newRows = [...newRows, row];
      }
      return;
    });
    updateRows(newRows);
  };

  const removeAllItems = () => {
    updateRows([]);
  };

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {!isTablet && (
              <TableCell>
                {translate("resources.products.fields.name", 1)}
              </TableCell>
            )}
            <TableCell align={"right"}>
              {translate("resources.products.fields.amount")}
            </TableCell>
            <TableCell align="right">
              {translate("resources.products.fields.price")}
            </TableCell>
            <TableCell align="right" className={classes.discountCell}>
              {translate("resources.products.fields.discount")}
            </TableCell>
            <TableCell align="right" className={classes.totalCell}>
              {translate("resources.products.fields.total")}
            </TableCell>
            <TableCell align="right" className={classes.deleteCell}>
              <Button disabled={rows.length === 0} onClick={removeAllItems}>
                <DeleteIcon color={rows.length ? "error" : "disabled"} />
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <Fragment key={row.product_id}>
              {isTablet && (
                <TableRow key={row.name}>
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan="5"
                    className={deviceClasses.tabletProductCell}
                  >
                    {row.name}
                  </TableCell>
                </TableRow>
              )}
              <TableRow key={row.product_id}>
                {!isTablet && (
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                )}
                <TableCell align={"right"}>
                <EditableField
                    field="amount"
                    rows={rows}
                    index={i}
                    minValue={1}
                    updateRows={updateRows}
                  ></EditableField>
                </TableCell>
                <TableCell align="right">
                  <EditableField
                    field="price"
                    adornment="CRC"
                    rows={rows}
                    index={i}
                    minValue={1}
                    updateRows={updateRows}
                  ></EditableField>
                </TableCell>
                <TableCell align="right" className={classes.discountCell}>
                  <EditableField
                    field="discount"
                    adornment="%"
                    rows={rows}
                    index={i}
                    maxValue={99}
                    updateRows={updateRows}
                  ></EditableField>
                </TableCell>
                <TableCell className={classes.totalCell} align="right">
                  <span>{numeral(rows[i].total).format("0,0.00")} CRC</span>
                </TableCell>
                <TableCell className={classes.deleteCell} align="right">
                  <Button onClick={() => removeItem(i)}>
                    <DeleteIcon color="primary" />
                  </Button>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductTable;

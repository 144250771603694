import React from "react";
import {
  SelectInput,
  ReferenceInput,
  SearchInput,
  Filter,
  useTranslate,
} from "react-admin";

import { makeStyles } from "@material-ui/core";

import { getCurrentUser } from "../../auth/provider";
import OrderStatusInput from "./OrderStatusInput";

export const defaultFilters = () => {
  const user = getCurrentUser();
  return { created_by_user_id: user ? user.id : null };
};

const useStyles = makeStyles({
  root: { paddingLeft: 8, padddingRight: 8 },
});

export const OrdersFilters = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const user = getCurrentUser();
  return (
    <Filter className={classes.root} {...props}>
      <SearchInput source="q" alwaysOn />
      <OrderStatusInput alwaysOn />
      {/* <ReferenceInput
        sort={{ field: "name", order: "ASC" }}
        source="created_by_user_id"
        reference="users"
        alwaysOn
      >
        <SelectInput
          translateChoice={false}
          optionText={(item) =>
            item.id === user.id ? translate("current_user") : item.name
          }
        />
        </ReferenceInput>
      */}
    </Filter>
  );
};

export default OrdersFilters;

import icon from "@material-ui/icons/HomeWork"

export { default as BrandCreate } from "./brand/create";
export { default as CategoryCreate } from "./category/create";
export { default as ProductCreate } from "./product/create";
export { default as ProductList } from "./product/list";
export { default as ProductEdit } from "./product/edit";

export { default as TypeCreate } from "./type/create";

export { default as PurchaseList } from "./purchase/list";
export { default as PurchaseCreate } from "./purchase/create";
export { default as PurchaseEdit } from "./purchase/edit";

export default { icon } ;
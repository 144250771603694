import React from "react";
import { useMutation, useNotify, useRedirect, Button } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ApplyIcon from "@material-ui/icons/Done";
import ShippingIcon from "@material-ui/icons/Receipt";
import WaitIcon from "@material-ui/icons/HourglassFull";

import PropTypes from "prop-types";
import { DRAFT, APPROVED, WAITING_SHIPMENT } from "../statusList";

const disableRowClickOnButton = (e, action) => {
  e.stopPropagation();
  e.preventDefault();
  action(e);
};

const ApplyButton = ({ record, resource, ...rest }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [apply, { loading }] = useMutation(
    {
      type: "update",
      resource,
      payload: { id: record.id, data: { id: record.id }, action: "apply" },
    },
    {
      redirect: null,
      undoable: false,
      onSuccess: () => {
        notify(
          `resources.${resource}.notification.apply_success`,
          "info",
          {},
          false
        );
        redirectTo(`/${resource}`);
      },
      onFailure: () => {
        notify(`resources.${resource}.notification.apply_error`, "warning");
      },
    }
  );
  return (
    <Button
      {...rest}
      label={null}
      variant="outlined"
      onClick={(e) => disableRowClickOnButton(e, apply)}
      disabled={loading}
    >
      <ApplyIcon />
    </Button>
  );
};

const ShipmentButton = ({ record, resource, ...rest }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [shipping, { loading }] = useMutation(
    {
      type: "update",
      resource,
      payload: { id: record.id, data: { id: record.id }, action: "archive" },
    },
    {
      undoable: false,
      onSuccess: async (result) => {
        notify(
          `resources.${resource}.notification.shipment_success`,
          "info",
          {},
          false
        );
        if (result.data && result.data.pdf) {
          await new Promise(resolve => setTimeout(resolve, 6000));
          window.open(result.data.pdf, "_blank");
        } else {
          notify(
            `resources.${resource}.notification.shipment_no_pdf`,
            "warning"
          );
        }
        redirectTo(`/${resource}`);
      },
      onFailure: () => {
        notify(`resources.${resource}.notification.shipment_error`, "warning");
      },
    }
  );
  return (
    <Button
      {...rest}
      label={null}
      variant="outlined"
      onClick={(e) => disableRowClickOnButton(e, shipping)}
      disabled={loading}
    >
      <ShippingIcon />
    </Button>
  );
};

const WaitButton = ({ record, resource, ...rest }) => {
  const notify = useNotify();
  const redirectTo = useRedirect();
  const [wait, { loading }] = useMutation(
    {
      type: "update",
      resource,
      payload: { data: { id: record.id }, action: "wait" },
    },
    {
      undoable: false,
      onSuccess: () => {
        notify(
          `resources.${resource}.notification.wait_success`,
          "info",
          {},
          false
        );
        redirectTo(`/${resource}`);
      },
      onFailure: () => {
        notify(`resources.${resource}.notification.wait_error`, "warning");
      },
    }
  );
  return (
    <Button
      {...rest}
      label={null}
      variant="outlined"
      onClick={(e) => disableRowClickOnButton(e, wait)}
      disabled={loading}
    >
      <WaitIcon />
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  margin: {
    marginLeft: theme.spacing(1),
  },
}));

const OrderActionsField = ({ record, source, sortable, ...rest }) => {
  const classes = useStyles();
  const status = record[source];
  if (status === DRAFT) {
    return <ApplyButton record={record} {...rest} />;
  } else if (status === WAITING_SHIPMENT) {
    return <ShipmentButton record={record} {...rest} />;
  } else if (status === APPROVED) {
    return (
      <>
        <ShipmentButton record={record} {...rest} />
        <WaitButton record={record} className={classes.margin} {...rest} />
      </>
    );
  }
  return null;
};

OrderActionsField.propTypes = {
  record: PropTypes.shape({ status: PropTypes.string }),
  source: PropTypes.string,
  sortable: PropTypes.bool,
};

OrderActionsField.defaultProps = {
  source: "status",
  label: "actions",
  sortable: false,
};

export default OrderActionsField;

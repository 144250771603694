import { AutocompleteInput } from "react-admin";

import React from "react";
import {
  Show,
  ShowButton,
  SimpleShowLayout,
  RichTextField,
  DateField,
  List,
  Edit,
  Create,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
  EditButton,
  ReferenceInput,
  BooleanField,
  NumberInput,
  RadioButtonGroupInput,
  SelectInput,
  SimpleForm,
  BooleanInput,
  TextInput,
  email,
  Filter
} from "react-admin";

const validateEmail = email();

export default props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

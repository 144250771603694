import React from "react";
import {
  Show,
  ShowButton,
  SimpleShowLayout,
  RichTextField,
  DateField,
  List,
  Edit,
  Create,
  Datagrid,
  ReferenceField,
  TextField,
  NumberField,
  EditButton,
  ReferenceInput,
  BooleanField,
  NumberInput,
  RadioButtonGroupInput,
  SelectInput,
  SimpleForm,
  BooleanInput,
  TextInput,
  email,
  Filter,
} from "react-admin";

const validateEmail = email();

export default (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />

      <NumberInput source="credit_limit" />
      <NumberInput source="credit_term" />
      <TextInput source="identification" />

      <TextInput validate={validateEmail} source="email_for_invoice" />

      <TextInput source="phone" />
      <SelectInput
        source="province"
        choices={[
          { id: "san jose", name: "san jose" },
          { id: "heredia", name: "heredia" },
          { id: "cartago", name: "cartago" },
          { id: "alajuela", name: "alajuela" },
          { id: "guanacaste", name: "guanacaste" },
          { id: "puntarenas", name: "puntarenas" },
          { id: "limon", name: "limon" },
        ]}
      />

      <TextInput source="city" />

      <TextInput source="address" />

      <ReferenceInput
        label="Transporte"
        source="transport_id"
        reference="transports"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <ReferenceInput
        label="Vendedor"
        source="customer_rep_id"
        reference="customer_reps"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

import React from "react";
import { Create, SimpleForm, required } from "react-admin";

import useFormStyles from "./styles";
import CustomerCard from "./createEditCards/CustomerCard";
import ProductsCard from "./productsSelector/ProductsCard";
import ToolbarCard from "./createEditCards/ToolbarCard";
import { parse } from 'query-string';

export default (props) => {
  const { customer_id: customer_id_string } = parse(props.location.search);
  const customer_id = customer_id_string ? parseInt(customer_id_string, 10) : "";
  const classes = useFormStyles();
  return (
    <Create className={classes.root} {...props}>
      <SimpleForm toolbar={<ToolbarCard />} redirect="list" defaultValue={{ customer_id }} >
        <CustomerCard />
        <ProductsCard />
      </SimpleForm>
    </Create>
  );
};

import { TextInput } from "react-admin";
import SelectField from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { Button, Grid, Box } from "@material-ui/core";

import ProductSelector from "./productSelector";

import React, { Fragment } from "react";
import {
  Create,
  SimpleForm,
  Toolbar,
  Edit,
  SaveButton,
  TopToolbar,
  ShowButton,
  useTranslate,
} from "react-admin";

import { useForm } from "react-final-form";

import ProductTable from "./productTable";

import { useUpdate, useRefresh, useNotify } from "react-admin";

const SaveWithNoteButton = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm();

  const handleClick = React.useCallback(() => {
    // change the average_note field value
    form.change(
      "created_by_user_id",
      parseInt(window.localStorage.getItem("userId"))
    );

    handleSubmitWithRedirect("edit");
  }, [form]);

  // override handleSubmitWithRedirect with custom logic
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const PostCreateToolbar = (props) => {
  const translate = useTranslate();
  return (
  <Toolbar {...props}>
    <SaveWithNoteButton
      label={translate("ra.button.save")}
      redirect="show"
      submitOnEnter={false}
      variant="text"
    />
  </Toolbar>
  )};

const OrderOrigin = (...rest) => {
  const form = useForm();

  const [selectedProducts, setSelectedProducts] = React.useState([]);

  const onSelectProducts = (products) => {
    setSelectedProducts(products);
  };

  let [rows, setRows] = React.useState(form.getState().values.items_json || []);
  return (
    <Fragment>
      <ProductTable
        updateRows={(rows) => {
          setRows(rows);
        }}
        rows={rows}
        form={form}
      />
      <Box m={2}>
        <Grid container>
          <ProductSelector rows={rows} onSelect={onSelectProducts} />
          <Button
            onClick={() => {
              var newRows = [
                ...rows,
                ...selectedProducts.map((item) => ({
                  product_id: item.id,
                  name: item.name,
                })),
              ];
              setRows(newRows);
              form.change("items_json", newRows);
            }}
          >
            Add
          </Button>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<PostCreateToolbar />}>
      <TextInput source="reference" />

      <OrderOrigin souce={"items_json"} />
    </SimpleForm>
  </Edit>
);

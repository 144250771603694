import {
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

import { Button, Grid, Box } from "@material-ui/core";

import React, { Fragment } from "react";
import { Create, SimpleForm, Toolbar, SaveButton } from "react-admin";

import { useForm } from "react-final-form";

const SaveWithNoteButton = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm();

  const handleClick = React.useCallback(() => {
    // change the average_note field value
    form.change(
      "created_by_user_id",
      parseInt(window.localStorage.getItem("userId"))
    );

    handleSubmitWithRedirect("edit");
  }, [form]);

  // override handleSubmitWithRedirect with custom logic
  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export default (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

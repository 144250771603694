import React, { useState, Fragment } from "react";
import { useLogin, useNotify, Notification, useTranslate } from "react-admin";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TILogo from '../tilogo.png';


const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    margin: "auto"
  },
  name: {
    margin: "auto"
  },
  card: {
    background: "white",
    margin: "auto",
    borderRadius: "20px",
    opacity: 0.7
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    backgroundColor: "black",
    margin: theme.spacing(3, 0, 2),
    borderRadius: "20px"
  },
  logo: {
    width: "200px",
    height: "200px",
    borderRadius: "20px",
    margin: "auto"
  },
}));

let apiUrl = process.env.REACT_APP_API_URL || "/api";

export default () => {
  const classes = useStyles();
  const translate = useTranslate();
  const [email, setEmail] = useState("");

  const [code, setCode] = useState("");
  const [emailView, setEmailView] = useState(true);

  const login = useLogin();
  const notify = useNotify();
  const send = (e) => {
    e.preventDefault();
    const request = new Request(apiUrl + "/login/getCode", {
      method: "POST",
      body: JSON.stringify({ email: email }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          console.log(response);
          throw new Error(response.statusText);
        }
        return setEmailView(false);
      })
      .catch((error) => {
        notify(error.message || "login.error", "warning");
      });
  };

  const submit = (e) => {
    e.preventDefault();
    const request = new Request(apiUrl + "/login/autenticate", {
      method: "POST",
      body: JSON.stringify({
        code: code,
        email: email,
        applicationSid: process.env.REACT_APP_APPLICATION_SID,
        endpoint:
          navigator.userAgent.toLowerCase() +
          Math.floor(Math.random() * 1000 + 1),
      }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          console.log(response);
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        const { token } = json;
        return login({
          token      }).catch(() => notify("login.invalid", "warning"));
      })
      .catch((error) => {
        notify("login.invalid", "warning");
      });
  };

  return (
    <div className={classes.main}>
    <Container className={classes.card} component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
          <img className={classes.logo} src={TILogo} alt="React Logo" />
        {emailView ? (
          <Fragment>
            <form onSubmit={send} className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={translate("login.email")}
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {translate("login.getcode")}
              </Button>
            </form>
          </Fragment>
        ) : (
          <Fragment>
            <form onSubmit={submit} className={classes.form}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="code"
                label={translate("login.code")}
                name="code"
                autoFocus
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {translate("login.login")}
              </Button>
            </form>
          </Fragment>
        )}
      </div>
      <Box mt={8}></Box>
      <Notification />
    </Container>
    </div>
  );
};

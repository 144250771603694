import englishMessages from "ra-language-english";

export const messages = {
  ...englishMessages,

  login: {
    getcode: "Get Code",
    login: "Login",
    email: "Email Address",
    code: "Code",
    error: "An error occured while trying to log in",
    invalid: "Invalid email or password",
  },

  menu: {
    sales: "Sales",
    customers: "Customers",
    products: "Products",
    inventory: "Inventory"
  },

  status: {
    draft: "Draft",
    waiting_approval: "Waiting approval",
    on_hold: "On hold",
    approved: "Approved",
    waiting_shipment: "Waiting shipment",
    done: "Done",
  },
  region_name: "Region",
  actions: "Actions",
  current_user: "Me",
  loading: "Loading",
  resources: {
    orders: {
      name: "Order |||| Orders",
      fields: {
        created_at: "Date",
        customer_id: "Customer",
        customer_order_number: "Reference",
        total: "Total",
        created_by_user_id: "Owner",
        customer_region_id: "Region",
      },
      notification: {
        apply_success: "Apply successful",
        apply_error: "Apply failed",
        shipment_success: "Shipment successful",
        shipment_error: "Shipment failed",
        shipment_no_pdf: "No pdf were found to print",
        wait_success: "Shipment Wait successful",
        wait_error: "Shipment Wait failed",
      },
    },
    approvals: {
      name: "Order Approval |||| Orders Approval",
      fields: {
        created_at: "Date",
        customer_id: "Customer",
        customer_order_number: "Reference",
        total: "Total",
      },
      tabs: {
        main: "Waiting order",
      },
      action: {
        approve: "Approve",
        hold: "Put on hold",
      },
      empty: "No orders remaining",
      notification: {
        approve_success: "Approval successful",
        approve_error: "Approval failed",
        hold_success: "Put on hold successful",
        hold_error: "Put on hold failed",
      },
    },
    customers: {
      name: "Customer |||| Customers",
      fields: {},
      selection_warning:
        "The customer can't be changed becaused there are already some products selected",
    },
    customer_regions: {
      name: "Region |||| Regions",
      fields: {},
    },
    customer_reps: {
      name: "Seller |||| Sellers",
      fields: {},
    },
    products: {
      name: "Product |||| Products",
      fields: {
        name: "Name",
        amount: "Amount",
        price: "Price",
        discount: "Discount",
        total: "Total",
      },
      search: "Start typing to search products ...",
      no_result: "No result found",
      available: "avail.",
      selection_warning:
        "A customer must be selected before adding some products",
      list_error: "An error occured while listing products",
    },
    packing_lists: {
      name: "Packing list |||| Packing lists",
      fields: {},
    },
    shipping_routes: {
      name: "Shipping route |||| Shipping routes",
      fields: {},
    },
    product_price_lists: {
      name: "Product price list |||| Product price lists",
      fields: {},
    },
    product_price_list_items: {
      name: "Product price list item |||| Product price list items",
      fields: {},
    },
    product_price_list_customers: {
      name: "Product price list customer |||| Product price list customers",
      fields: {},
    },
    product_brands: {
      name: "Product brand |||| Product brands",
      fields: {},
    },
    transports: {
      name: "Transport |||| Transports",
      fields: {
        product_id: "Product",
        created_at: "Created at",
        updated_at: "Updated at",
      },
    },
    trucks: {
      name: "Truck |||| Trucks",
      fields: {},
    },
    users: {
      name: "User |||| Users",
      fields: {},
    },
    purchases: {
      name: "Purchase ||| Purchases",
      fields:{}
    }
  },
};

export default messages;

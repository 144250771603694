import React from "react";
import {
  SelectInput,
  ReferenceInput,
  SearchInput,
  Filter,
  useTranslate,
} from "react-admin";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: { paddingLeft: 8, padddingRight: 8 },
});

export const CustomerFilters = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Filter className={classes.root} {...props}>
      <SearchInput source="q" alwaysOn />
    </Filter>
  );
};

export default CustomerFilters;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDataProvider, useNotify, useTranslate } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import NothingIcon from "@material-ui/icons/NotInterested";
import CustomTextComponent from "./CustomTextComponent";
import CustomListComponent from "./CustomListComponent";
import CheckedIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  popperDisablePortal: {
    backgroundColor: "dimgrey",
    marginTop: 14,
    position: "relative",
    width: "auto !important",
    "& > div > ul": {
      height: 350,
      minHeight: 350,
      maxHeight: 350,
      overflowY: "auto",
    },
  },
  groupLabel: {
    backgroundColor: "dimgrey",
    fontWeight: "bolder",
    textTransform: "uppercase",
  },
  noOptions: {
    padding: "8px 0",
  },
  loading: {
    padding: "8px 0",
  },
}));

const AsynchronousSelector = ({
  listRef,
  showInactive,
  rows,
  customer_id,
  onSelect,
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState([]);

  const handleSelect = (selection) => {
    selection[0] && onSelect(selection[0]);
    setSelected([]);
  };

  useEffect(() => {
    let active = true;
    (async () => {
      if (!customer_id) return undefined;
      if (searchTerm.length === 0) return undefined;
      setLoading(true);
      dataProvider
        .getList("products", {
          metadata: { customer_id },
          pagination: { page: 1, perPage: 10 },
          sort: { field: "name", order: "ASC" },
          filter: {
            active: showInactive ? undefined : true,
            name: `%${searchTerm}%`,
          },
        })
        .then((result) => {
          setLoading(false);
          setOptions(result.data);
        })
        .catch((error) => {
          notify(`resources.products.list_error`, "warning");
        });
    })();

    return () => {
      active = false;
    };
  }, [searchTerm, customer_id, dataProvider, notify]);

  useEffect(() => {
    listRef &&
      listRef.current &&
      listRef.current.scrollIntoView({ behavior: "smooth" });
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onInputChange = (ev, val, reason) => {
    setSearchTerm(val);
  };

  const getDetailName = (name) => {
    const nameParts = name.split(":");
    return nameParts[nameParts.length - 1];
  };

  const getGroupName = (name) => {
    const nameParts = name.split(":");
    return nameParts.length > 1 ? nameParts[0] : null;
  };

  return (
    <Autocomplete
      onInputChange={onInputChange}
      id="asynchronous-products"
      multiple
      value={selected}
      onChange={(e, o) => {
        handleSelect(o);
      }}
      disablePortal
      disableCloseOnSelect
      PaperComponent={CustomListComponent}
      classes={classes}
      noOptionsText={
        <CustomTextComponent text={translate("resources.products.no_result")} />
      }
      loadingText={<CustomTextComponent text={translate("loading")} />}
      style={{
        paddingLeft: 16,
        paddingRight: 16,
        width: "calc(100% - 16px)",
      }}
      open={open}
      openOnFocus
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionDisabled={(option) => !option.inventory}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      groupBy={(option) => getGroupName(option.name)}
      renderOption={(option) => {
        return (
          <span
            style={{
              color: option.inventory ? "inherit" : "red",
              display: "flex",
              lineHeight: "1rem",
              alignSelf: "center",
            }}
          >
            {option.inventory ? <AddIcon /> : <NothingIcon />}
            <span
              style={{
                marginLeft: 10,
                alignSelf: "center",
                lineHeight: "1rem",
              }}
            >
              {getDetailName(option.name)}
            </span>
            <span
              style={{
                marginLeft: 10,
                alignSelf: "center",
                lineHeight: "1rem",
              }}
            >
              ({option.inventory || 0}{" "}
              {translate("resources.products.available")})
            </span>
            {rows.filter((row) => row.product_id === option.id).length > 0 && (
              <CheckedIcon
                style={{
                  marginLeft: 16,
                  color: "green",
                }}
              />
            )}
          </span>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={translate("resources.products.search")}
          variant="outlined"
          name="products"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AsynchronousSelector;

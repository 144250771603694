import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Paper, TextField } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

export default function ProductTable(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Cost</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, i) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">
                <EditableField
                  field="amount"
                  row={row}
                  rows={props.rows}
                  index={i}
                  form={props.form}
                  updateRows={props.updateRows}
                />
              </TableCell>
              <TableCell align="right">
                <EditableField
                  field="cost"
                  row={row}
                  rows={props.rows}
                  index={i}
                  form={props.form}
                  updateRows={props.updateRows}
                ></EditableField>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function EditableField(props) {
  const classes = useStyles();

  return (
    <TextField
      value={props.row[props.field]}
      onChange={e => {
        var newRows = props.rows.map((row, ii) => {
          if (ii === props.index) {
            row[props.field] = parseFloat(e.currentTarget.value);
          }
          return row;
        });
        props.form.change("items_json", newRows);
        props.updateRows(newRows);
      }}
    />
  );
}

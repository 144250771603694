import React, { cloneElement } from "react";
import { sanitizeListRestProps } from "react-admin";
import { useHistory } from "react-router-dom";
import CardgridBody from "./CardgridBody";
import Table from "@material-ui/core/Table";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    table: {
      tableLayout: "auto",
    },
    tbody: {},
    cell: {
      border: 0,
    },
  }),
  { name: "Cardgrid" }
);

const Cardgrid = ({ selectedId, ...props }) => {
  const classes = useStyles(props);
  const {
    basePath,
    body = <CardgridBody />,
    children,
    className,
    currentSort,
    data,
    expand,
    hasBulkActions,
    hover,
    ids,
    loading,
    loaded,
    onSelect,
    onToggleItem,
    resource,
    rowClick,
    setSort,
    size = "small",
    total,
    isRowSelectable,
    version,
    ...rest
  } = props;
  const history = useHistory();

  if (selectedId == null && props.ids.length > 0) {
    history.push(`/${props.resource}/${props.ids[0]}`);
  }

  return (
    <Table
      className={classnames(classes.table, className)}
      size={size}
      {...sanitizeListRestProps(rest)}
    >
      {cloneElement(
        body,
        {
          basePath,
          className: classes.tbody,
          classes,
          expand,
          rowClick,
          data,
          hasBulkActions,
          hover,
          ids,
          onToggleItem,
          resource,
          selectedId,
          isRowSelectable,
          version,
        },
        children
      )}
    </Table>
  );
};

export default Cardgrid;

import icon from "@material-ui/icons/ShoppingCart";

import OrderList from "./OrdersList";
import OrderCreate from "./OrderCreate";
import OrderEdit from "./OrderEdit";

export const OrdersIcon = icon;

export default {
  icon,
  list: OrderList,
  create: OrderCreate,
  edit: OrderEdit,
};
